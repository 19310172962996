/**
 * Copyright 2018, CLOUDPILOTS Software & Consulting GmbH
 *
 * Chat Page
 * @default
 *
 */

import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Cookies from 'js-cookie';
import ChatLauncher from '../../components/library/chat';
import Api from '../../services/Api';
import firestore from '../../services/firebase/firestore';
import auth from '../../services/firebase/auth';
import backgroundImage from './images/pier_cpl_1920-2.jpg';
import gcpLogo from './images/gcp_logo.png';
// onepirate theme
// import withRoot from '../../components/library/theme/withRoot';
// import ProductCategories from '../../components/library/theme/views/ProductCategories';
// import ProductSmokingHero from '../../components/library/theme/views/ProductSmokingHero';
// import AppFooter from '../../components/library/theme/views/AppFooter';
// import ProductHero from '../../components/library/theme/views/ProductHero';
// import ProductValues from '../../components/library/theme/views/ProductValues';
// import ProductHowItWorks from '../../components/library/theme/views/ProductHowItWorks';
// import ProductCTA from '../../components/library/theme/views/ProductCTA';
// import AppAppBar from '../../components/library/theme/views/AppAppBar';

/* eslint-disable */
const styles = (theme) => ({
  '@global': {
    body: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100%',
    },
  },
  'chatWindow': {
    // bottom: 170,
    // height: "calc(100% - 220px)",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      right: 0,
      bottom: 0,
      borderRadius: 0,
    },
  },
  'launcher': {
    // bottom: 100,
  },
  'launcherIcon': {
    // bottom: 100,
  },
  'link': {
    'color': `${theme.palette.secondary.contrastText} !important`,
    'backgroundColor': `${theme.palette.secondary.main} !important`,
    'padding': 0,
    'textDecoration': 'underline !important',
    '&:hover': {
      // backgroundColor: "white",
      backgroundColor: `${theme.palette.secondary.light} !important`,
      color: `${theme.palette.secondary.contrastText} !important`,
      textDecoration: 'underline !important',
    },
  },
});

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageUnsubscribe: undefined,
      chatId: undefined,
      supportedLanguages: ['en'],
      lang: (navigator.language || navigator.userLanguage || 'en').replace(
        /-.*/,
        '',
      ),
      messageList: [],
      isChatOpen: false,
      isApiLoaded: false,
      videoSettings: {
        autoPlay: true,
        muted: true,
        fluid: false,
        width: 280,
        height: 160,
        playsInline: true,
        disableControlbar: true,
      },
    };

    if (this.state.supportedLanguages.indexOf(this.state.lang) < 0) {
      this.state.lang = this.state.supportedLanguages[0];
    }
  }

  addMessage = async (message) => {
    const ref = await firestore
      .collection('Chats')
      .doc(this.state.chatId)
      .collection('Messages')
      .add({
        ...message,
        timestamp: new Date(),
      });
    console.info(`chat message added ${ref.id}`);
    return ref;
    // this.setState((prev) => ({
    //   messageList: [...prev.messageList, { ...message, timestamp: new Date() }],
    // }));
  };

  onMessageWasSent = async (message) => {
    console.log('chat message ', message);
    const { lang } = this.state;
    this.addMessage(message);
    const response = await Api.apis.chat.chat_message({
      message: {
        text: message.data.text || message.data.emoji,
        lang,
      },
    });
    console.info('got message from dialogflow', response.data);
    const { text } = response.body;
    this.addMessage({
      author: 'them',
      type: 'text',
      data: { text },
    });
  };

  messagesUpdated = (snapshot) => {
    // console.info("got snapshot", snapshot.docs);
    this.setState({
      messageList: snapshot.docs.map((message) => message.data()),
    });
  };

  onLangChanged = (lang) => {
    console.info('lang changed', lang);
    this.setState({ lang });
  };

  componentDidMount() {
    this.initChat();
  }

  toggleChat = () => {
    const { isChatOpen, chatId } = this.state;
    if (!isChatOpen && !chatId) {
      this.startChat();
    }

    this.setState((prevState) => ({
      isChatOpen: !prevState.isChatOpen,
    }));
  };

  setCookie = (name, value, minutes) => {
    // console.info("cookies? ", Cookies);
    const options = {};
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60000);
      options.expires = date;
    }

    Cookies.set(name, value, options);
  };

  getCookie = (name) => {
    return Cookies.get(name);
  };

  startChat = async () => {
    console.info('fetching token info');
    const cookieName = 'CloudpilotsChat';
    const tokenCookie = this.getCookie(cookieName);

    let tokenInfo = '';
    if (tokenCookie) {
      console.info('token info in cookie found');
      tokenInfo = JSON.parse(tokenCookie);
    } else {
      // console.info("no cookie found - request new tokenInfo");
      tokenInfo = (await Api.apis.chat.chat_start({
        options: {
          lang: this.state.lang,
          event: window.chatStartEvent || 'Welcome',
        },
      })).body;
      // this.setCookie(cookieName, JSON.stringify(tokenInfo), 30);
    }

    console.info('got token info', tokenInfo);

    const {
      token,
      uid,
      text: { text },
    } = tokenInfo;

    // auth not sufficient yet
    const authInfo = await auth.signInWithCustomToken(token);

    const accessToken = await auth.currentUser.getIdToken();

    this.setState((prev) => ({
      token: accessToken,
      uid,
      chatId: uid,
    }));
    if (!tokenCookie) {
      if (text) {
        this.addMessage({
          author: 'them',
          type: 'text',
          data: {
            text,
          },
        });
      }
    }

    Api.setToken(accessToken);

    console.info(`logged in for session ${uid}`, authInfo);
    this.setState({ isLoggedin: true });

    const messageUnsubscribe = firestore
      .collection('Chats')
      .doc(this.state.chatId)
      .collection('Messages')
      .orderBy('timestamp')
      .onSnapshot(this.messagesUpdated);
    this.setState({ messageUnsubscribe });
  };

  initChat = async () => {
    // console.info("chat init");
    await Api.init();
    this.setState({ isApiLoaded: true });
  };

  componentWillUnmount() {
    const { messageUnsubscribe } = this.state;
    if (messageUnsubscribe) {
      messageUnsubscribe();
    }
  }

  render() {
    const {
      isApiLoaded,
      isChatOpen,
      lang,
      supportedLanguages,
      videoSettings,
    } = this.state;
    const { classes } = this.props;
    const theme = classes;

    return (
      <>
        {isApiLoaded && (
          <ChatLauncher
            agentProfile={{
              teamName: 'Cloudi @ Google Cloud Summit',
              imageUrl: gcpLogo,
            }}
            onMessageWasSent={this.onMessageWasSent}
            messageList={this.state.messageList}
            showEmoji={false}
            onLangChanged={this.onLangChanged}
            lang={lang}
            languages={supportedLanguages}
            isOpen={isChatOpen}
            handleClick={this.toggleChat}
            disableMessageIcon
            theme={theme}
            showLangSwitcher={false}
            videoSettings={videoSettings}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(Chat);
