/* eslint-disable no-shadow */
import firebase from './firebase';
import 'firebase/firestore';
import Api from '../Api';

const firestore = firebase.firestore();

const settings = {
  // defaults to true in 5.8.1
  // timestampsInSnapshots: true
};
firestore.settings(settings);

let namespaceSuffix;
process.env.NODE_ENV === 'development'
  ? (namespaceSuffix = '-dev')
  : (namespaceSuffix = '');

const firestoreFactory = firestore
  .collection('namespaces')
  .doc(`${Api.getCustomer()}${namespaceSuffix}`);

const init = () => {
  const firestore = firebase.firestore();

  const settings = {
    // defaults to true in 5.8.1
    // timestampsInSnapshots: true
  };
  firestore.settings(settings);
  return firestore;
};

init();

export { firestore as default, firestoreFactory, init };
