/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * firebase
 * @default
 *
 */

import firebase from 'firebase/app';
import config from '../../config/config.json';

const app = firebase.initializeApp(config.firebase);

export { firebase as default, app };
