import Loadable from 'react-loadable';

const AsyncChatLoader = Loadable({
  loader: () =>
    import('./Launcher' /* webpackChunkName: "components.chat" */).then(
      (m) => m,
    ),
  loading: () => '',
});

export default AsyncChatLoader;
