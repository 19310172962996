/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * index
 * @default
 *
 */

// *** Do not modify this file, entry point core/app.js *** //

// import App from 'core/app';
import Chat from 'pages/Chat';
import 'core/index.css';
import * as serviceWorker from 'core/serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(<Chat />, document.getElementById('root'));

serviceWorker.unregister();
