/* eslint-disable max-statements */
/**
 * Copyright 2018, CLOUDPILOTS Software & Consulting GmbH
 *
 * myplace
 * @default
 *
 */

import Swagger from 'swagger-client';
import firebaseAuth from './firebase/auth';
import firebase from './firebase/firebase';

class Api {
  constructor(props) {
    this.state = {
      api: `${process.env.REACT_APP_API_URL}/api/v1`,
      token: '',
      accessToken: '',
      userInfo: null,
      hasLoaded: false,
    };

    this.props = {
      showError: (status, message) => {},
      showSuccess: (status, message) => {},
    };
  }

  // eslint-disable-next-line consistent-return
  init = async (token) => {
    if (!this.state.hasLoaded) {
      return this.load(token);
    }
  };

  load = async (token) => {
    try {
      this.state.token = token;
      this.swagger = null;
      this.apis = null;

      const client = await Swagger({
        url: `${this.state.api}/swagger.json`,
        requestInterceptor: (req) => {
          /* eslint no-param-reassign: "error" */
          req.headers.Authorization = `Bearer ${this.state.token}`;
          return req;
        },
      });

      if (client.errors.length) console.error(client.errors);

      this.swagger = client;
      this.apis = client.apis;
      this.spec = client.spec;
      this.hasLoaded = true;

      console.info('@Api', client.apis);

      window.Api = this;
      // await this.initFirebase();

      return client;
    } catch (error) {
      console.error('failed to load swagger definition', error);
      throw error;
    }
  };

  isReady = () => this.state.hasLoaded;

  setToken = (token) => {
    this.state.token = token;
  };

  mypermissions = async () => {
    if (!this._mypermissions) {
      this._mypermissions = this._authInfo && this._authInfo.claims.permissions;
    }
    return this._mypermissions || {};
  };

  isAdmin = async () => {
    if (this._isAdmin === undefined) {
      this._isAdmin = this._authInfo && this._authInfo.claims.isAdmin;
    }

    return this._isAdmin;
  };

  initFirebase = async () => {
    try {
      const { token } = (await this.apis.permissions.token()).obj;
      window.firebase = firebase;
      const authResult = await firebaseAuth.signInWithCustomToken(token);
      this._authInfo = await authResult.user.getIdTokenResult();
    } catch (error) {
      console.error('firebase failed', error);
    }
    return this._authInfo;
  };

  getFirebaseAuth = () => this._authInfo;

  getCustomer = () =>
    this._authInfo ? this._authInfo.claims.customer : 'my_customer';
}

export default new Api();
